import React from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Flex,
  useDisclosure,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

export const Nav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        bg={useColorModeValue("white", "black")}
        px={4}
        mb={1}
        boxShadow="0 0 0 0.25em #ffd520"
        outline={useColorModeValue("dashed 0.25em black", "dashed 0.25em red")}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <Link to="/">
              <Heading
                size="lg"
                color={useColorModeValue("red.500", "red.500")}
                fontWeight="normal"
                className={useColorModeValue("md-outline", "md-outline-dark")}
                fontFamily="Alfa Slab One"
                letterSpacing="3px"
              >
                MARYLAND WILL WIN
              </Heading>
            </Link>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
