import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  Box,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useColorModeValue,
} from "@chakra-ui/react";

import Map, {
  Source,
  Layer,
  FillLayer,
  Popup,
  AttributionControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import seatGeek from "../../seatgeek";
import mdSections from "../../geo/maryland_stadium_sections";

import { SectionChart } from "./Charts";

const seatGeekURL =
  "https://seatgeek-tileserver.global.ssl.fastly.net/normal_350/437/{z}/{x}/{y}.mvt";

const sectionStyle = {
  id: "sections",
  type: "fill",
  paint: {
    "fill-color": {
      property: "percentage",
      stops: [
        [0, "#a50026"],
        [10, "#d73027"],
        [20, "#f46d43"],
        [30, "#fdae61"],
        [40, "#fee090"],
        [50, "#e0f3f8"],
        [60, "#abd9e9"],
        [70, "#74add1"],
        [80, "#4575b4"],
        [90, "#313695"],
      ],
    },
    "fill-opacity": 0.75,
  },
};

export const Sections = ({ ticketData }) => {
  const [loading, setLoading] = useState(true);
  const [latest, setLatest] = useState(null);
  const [section, setSection] = useState(null);
  const [mapSections, setMapSections] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);

  const updateSections = () => {
    let updatedFeatures = mdSections.features.map((feature) => {
      let newFeature = feature;
      let newProps = feature.properties;

      for (const sect in latest.data) {
        if (sect == feature.properties.section_name) {
          let sectData = latest.data[sect]["pl"];
          let sectCount = 0;
          let sectTotal = 0;
          sectData.forEach((subSect) => {
            sectCount += subSect["cn"];
            sectTotal += subSect["tc"];
          });
          newProps["total"] = sectTotal;
          newProps["available"] = sectCount;
          newProps["percentage"] =
            Math.round((sectCount / sectTotal) * 10000) / 100;
        }
      }
      newFeature.properties = newProps;
      return newFeature;
    });
    let newSections = new Object();
    newSections["features"] = updatedFeatures;
    newSections["type"] = mdSections["type"];

    setMapSections(newSections);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setSection(null);
    let latestData = ticketData[ticketData.length - 1];
    setLatest(latestData);
  }, [ticketData]);

  useEffect(() => {
    if (latest) {
      // trick map into rerendering
      setMapSections(null);
      setTimeout(() => {
        updateSections();
      }, 500);
    }
  }, [latest]);

  const clickSection = (e) => {
    let feature = e.features[0];
    if (feature) {
      let properties = feature.properties;
      setSection(properties);
    }
  };

  const onHover = useCallback((event) => {
    const { features } = event;
    const lng = event.lngLat.lng;
    const lat = event.lngLat.lat;
    const hoveredFeature = features && features[0];
    // prettier-ignore
    setHoverInfo(hoveredFeature && {feature: hoveredFeature, longitude:lng,latitude:lat});
  }, []);

  return (
    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={3}>
      <Card bg={useColorModeValue("white", "gray.900")}>
        {/* {loading ? <LinearProgress color="error" /> : null} */}
        <Map
          initialViewState={{
            longitude: 0,
            latitude: 0,
            zoom: 2.5,
          }}
          style={{ width: "100%", height: "44vh" }}
          mapStyle={seatGeek}
          cursor="crosshair"
          dragPan={false}
          scrollZoom={false}
          touchZoomRotate={false}
          doubleClickZoom={false}
          interactiveLayerIds={["sections"]}
          onClick={clickSection}
          onMouseMove={onHover}
          mapboxAccessToken="pk.eyJ1IjoiamtlbmlzdG9uIiwiYSI6ImNsNXFwZGg3ajIwODUzY3E5NHpsY25veDkifQ.jXNZHa--HXaRDrgsu5a3qg"
          attributionControl={false}
        >
          <Source id="sections" type="geojson" data={mapSections}>
            <Layer {...sectionStyle} />
          </Source>
          {hoverInfo && (
            // <Popup
            //   longitude={hoverInfo.longitude}
            //   latitude={hoverInfo.latitude}
            //   offset={[0, -25]}
            //   closeButton={false}
            //   className="county-info"
            // >
            <Box
              sx={{
                backgroundColor: "black",
                color: "white",
                opacity: 0.75,
                width: "25%",
                pl: 1,
                py: 1,
                // borderRadius: "2px",
              }}
            >
              Section: {hoverInfo.feature.properties.section_name}
              <br />
              Available Tickets: {hoverInfo.feature.properties.available}
              <br />
              Capacity: {hoverInfo.feature.properties.total}
            </Box>
            // </Popup>
          )}
        </Map>
      </Card>

      <Card
        elevation={3}
        sx={{ height: "44vh" }}
        bg={useColorModeValue("white", "gray.900")}
      >
        <CardHeader>
          {section ? (
            <>
              <Text variant="h6">
                {section.section_name} - {section.percentage}% Available
              </Text>
            </>
          ) : (
            <Text variant="h6">
              Click a section on the map to see detailed availability.
            </Text>
          )}
        </CardHeader>
        {section ? (
          <CardBody sx={{ height: "70%" }}>
            <SectionChart ticketData={ticketData} section={section} />
          </CardBody>
        ) : null}
      </Card>
    </SimpleGrid>
  );
};
