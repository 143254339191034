import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";

import { db } from "../firebase/config";

import {
  query,
  getDocs,
  collection,
  doc,
  getDoc,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import "../css/md.css";

let theme = createTheme();

const Logos = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box className="borders" sx={{ mb: 1 }} />
      <Grid container spacing={2} sx={{ py: 1 }}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", fontSize: "2rem" }}>
                Good 👍
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  image="https://content.sportslogos.net/logos/32/743/full/maryland_terrapins_logo_secondary_19961895.png"
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  image="https://content.sportslogos.net/logos/32/743/full/maryland_terrapins_logo_primary_20052066.png"
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  image="https://content.sportslogos.net/logos/32/743/full/maryland_terrapins_logo_primary_19737774.png"
                />
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  image="https://content.sportslogos.net/logos/32/743/full/maryland_terrapins_logo_alternate_1965_sportslogosnet-4158.png"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", fontSize: "2rem" }}>
                Bad 👎
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  image="https://content.sportslogos.net/logos/32/743/full/maryland_terrapins_logo_primary_20125749.png"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Logos;
