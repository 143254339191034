import React, { ReactNode, useEffect } from "react";
import {
  Link,
  matchPath,
  matchRoutes,
  useMatch,
  useLocation,
} from "react-router-dom";
import {
  Heading,
  IconButton,
  Button,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  VStack,
  Tag,
} from "@chakra-ui/react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
} from "react-icons/fi";
import { FaHome, FaChartArea, FaMedium } from "react-icons/fa";
import { MdEventSeat } from "react-icons/md";
import { GiBasketballJersey, GiTurtleShell } from "react-icons/gi";

const LinkItems = [
  { name: "Home", icon: <FaHome />, route: "/", active: true },
  {
    name: "Tracking Terptix",
    icon: <FaChartArea />,
    route: "/ticket-tracker",
    active: true,
  },
  { name: "Attendance Database", icon: <MdEventSeat />, active: false },
  { name: "Uniform Database", icon: <GiBasketballJersey />, active: false },
  { name: "Logo Ranker", icon: <FaMedium />, active: false },
];

export const Sidebar = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box h="100%" bg={useColorModeValue("gray.50", "black")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", sm: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <MobileNav display={{ base: "flex", sm: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, sm: 180, md: 60 }} h="100%">
        {children}
      </Box>
    </Box>
  );
};

const SidebarContent = ({ onClose, ...rest }) => {
  const location = useLocation();

  //   useEffect(() => {
  //     console.log(location);
  //   }, [location]);

  return (
    <Box
      bg={useColorModeValue("white", "black")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", sm: 180, md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <VStack textAlign="left" p={3}>
        <Link style={{ width: "100%" }} to="/">
          <Tag
            size="lg"
            w="100%"
            variant={location.pathname == "/" ? "solid" : "outline"}
            bg={location.pathname == "/" ? "red.500" : "white"}
            color={location.pathname == "/" ? "white" : "black"}
            _hover={{
              background: "black",
              color: "#ffd520",
            }}
          >
            <Text fontSize="lg" fontWeight={600} w="100%" m={1} mt={2}>
              Home
            </Text>
          </Tag>
        </Link>

        <Link style={{ width: "100%" }} to="/ticket-tracker">
          <Tag
            size="lg"
            w="100%"
            variant={
              location.pathname.includes("/ticket-tracker")
                ? "solid"
                : "outline"
            }
            bg={
              location.pathname.includes("/ticket-tracker")
                ? "red.500"
                : "white"
            }
            color={
              location.pathname.includes("/ticket-tracker") ? "white" : "black"
            }
            _hover={{
              background: "black",
              color: "#ffd520",
            }}
          >
            <Text fontSize="lg" fontWeight={600} w="100%" m={1} mt={2}>
              Tracking TerpTix
            </Text>
          </Tag>
        </Link>
      </VStack>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const location = useLocation();

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Heading ml={4} mt={2} size="lg">
        {" "}
        {location.pathname == "/"
          ? "Home"
          : location.pathname.includes("/ticket-tracker")
          ? "Tracking TerpTix"
          : ""}
      </Heading>
    </Flex>
  );
};
