const seatGeek = {
  layers: [
    {
      source: "buster",
      "source-layer": "outline-shadow",
      type: "line",
      id: "outline-shadow",
      paint: {
        "line-blur": 15,
        "line-color": "#000000",
        "line-opacity": 0.1,
        "line-width": {
          stops: [
            [1, 5],
            [4, 15],
          ],
        },
      },
    },
    {
      source: "buster",
      "source-layer": "outline",
      type: "fill",
      id: "outline-fill",
      paint: {
        "fill-color": "#FFF",
      },
    },
    {
      source: "buster",
      "source-layer": "outline",
      type: "line",
      id: "outline-stroke",
      paint: {
        "line-color": "#B7B6B8",
        "line-width": {
          stops: [
            [1, 1],
            [4, 4],
          ],
        },
      },
    },
    {
      source: "buster",
      "source-layer": "field-background",
      type: "fill",
      id: "field-background",
      paint: {
        "fill-color": {
          default: "#77914B",
          property: "field_background",
          type: "categorical",
          stops: [["tennis-us-open", "#73aa65"]],
        },
      },
    },
    {
      paint: {
        "line-color": "#77914B",
        "line-width": 2,
      },
      filter: ["!=", "field_background", "tennis-us-open"],
      source: "buster",
      "source-layer": "field-background",
      type: "line",
      id: "field-background-stroke",
    },
    {
      paint: {
        "fill-color": "rgb(157, 103, 79)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "outfield-dirt"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "outfield-dirt",
    },
    {
      paint: {
        "fill-color": "rgb(58, 99, 29)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "outfield-grass"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "outfield-grass",
    },
    {
      paint: {
        "fill-color": "rgba(86, 129, 75, 0.5)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "outfield-grass-stripes-1"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "outfield-grass-stripes-1",
    },
    {
      paint: {
        "fill-color": "rgba(86, 129, 75, 0.5)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "outfield-grass-stripes-2"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "outfield-grass-stripes-2",
    },
    {
      paint: {
        "fill-color": "rgb(202, 139, 88)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "infield-dirt"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "infield-dirt",
    },
    {
      paint: {
        "fill-color": "rgb(58, 99, 29)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "infield-grass"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "infield-grass",
    },
    {
      paint: {
        "fill-color": "rgba(86, 129, 75, 0.5)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "infield-grass-stripes-1"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "infield-grass-stripes-1",
    },
    {
      paint: {
        "fill-color": "rgba(86, 129, 75, 0.5)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "infield-grass-stripes-2"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "infield-grass-stripes-2",
    },
    {
      paint: {
        "fill-color": "rgb(202, 139, 88)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "infield-pitchers-mound"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "infield-pitchers-mound",
    },
    {
      paint: {
        "fill-color": "#FFFFFF",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "markings"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "markings",
    },
    {
      paint: {
        "fill-color": "rgb(  77,  77,  77)",
        "fill-antialias": true,
      },
      filter: [
        "all",
        ["==", "field_layer", "stage-floor"],
        ["==", "field_type", "concert"],
      ],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "stage-floor",
    },
    {
      paint: {
        "line-color": "rgb(  77,  77,  77)",
        "line-width": 2,
      },
      filter: [
        "all",
        ["==", "field_layer", "stage-floor"],
        ["==", "field_type", "concert"],
      ],
      source: "buster",
      "source-layer": "field",
      type: "line",
      id: "stage-floor-stroke",
    },
    {
      paint: {
        "fill-color": "rgb( 175, 175, 175)",
        "fill-antialias": true,
      },
      filter: [
        "all",
        ["==", "field_layer", "stage-line"],
        ["==", "field_type", "concert"],
      ],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "stage-line",
    },
    {
      paint: {
        "fill-color": "rgb( 206, 171, 130)",
        "fill-antialias": true,
      },
      filter: [
        "all",
        ["==", "field_layer", "stage-floor"],
        ["==", "field_type", "theater"],
      ],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "stage-floor-theater",
    },
    {
      paint: {
        "fill-color": {
          property: "field_layer",
          type: "categorical",
          stops: [
            ["hardwood-floor-panel-1", "rgb( 206, 171, 130)"],
            ["hardwood-floor-panel-3", "rgb( 208, 172, 129)"],
            ["hardwood-floor-panel-2", "rgb( 207, 172, 131)"],
            ["hardwood-floor-panel-5", "rgb( 211, 179, 138)"],
            ["hardwood-floor-panel-4", "rgb( 210, 175, 134)"],
            ["hardwood-floor-panel-6", "rgb( 212, 177, 135)"],
          ],
        },
        "fill-antialias": true,
      },
      filter: [
        "all",
        ["==", "field_type", "theater"],
        [
          "in",
          "field_layer",
          "hardwood-floor-panel-1",
          "hardwood-floor-panel-2",
          "hardwood-floor-panel-3",
          "hardwood-floor-panel-4",
          "hardwood-floor-panel-5",
          "hardwood-floor-panel-6",
        ],
      ],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "field-layers-theater-hardwood-floor",
    },
    {
      paint: {
        "fill-color": "rgb( 185, 147, 107)",
        "fill-antialias": true,
      },
      filter: [
        "all",
        ["==", "field_layer", "stage-line"],
        ["==", "field_type", "theater"],
      ],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "stage-line-theater",
    },
    {
      source: "raster-tiles",
      minzoom: 0,
      type: "raster",
      id: "field-tiles",
      layout: {
        visibility: "visible",
      },
    },
    {
      paint: {
        "fill-color": "rgb( 185,  39,  45)",
        "fill-antialias": true,
      },
      filter: ["==", "field_layer", "stage-curtain"],
      source: "buster",
      "source-layer": "field",
      type: "fill",
      id: "stage-curtain",
    },
    {
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-color": "#F00",
        "circle-radius": 5,
      },
      source: "buster",
      "source-layer": "field-center",
      type: "circle",
      id: "field-center",
    },
    {
      paint: {
        "fill-antialias": false,
        "fill-opacity": 0,
      },
      filter: ["==", "object_type", "zone"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-zone-invisible",
      interactive: true,
    },
    {
      paint: {
        "fill-color": {
          default: "#E7E6E8",
          property: "supporter_section",
          type: "categorical",
          stops: [
            ["home", "#CFDDEB"],
            ["visitors", "#ebd6cf"],
          ],
        },
        "fill-antialias": false,
      },
      filter: [
        "all",
        ["==", "object_type", "section"],
        ["!=", "map_type", "small-map"],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-section",
      interactive: true,
    },
    {
      filter: [
        "all",
        ["==", "object_type", "section"],
        ["==", "map_type", "small-map"],
      ],
      source: "buster",
      "source-layer": "shape",
      paint: {
        "fill-color": "#E7E6E8",
        "fill-antialias": false,
      },
      maxzoom: 5,
      type: "fill",
      id: "map-shapes-section-small-map",
      interactive: true,
    },
    {
      paint: {
        "fill-color": "#E7E6E8",
        "fill-antialias": false,
      },
      filter: [
        "all",
        ["==", "object_type", "row"],
        ["==", "map_type", "small-map"],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-row-fill",
      interactive: true,
    },
    {
      paint: {
        "fill-antialias": false,
        "fill-opacity": 0,
      },
      filter: [
        "all",
        ["==", "object_type", "section"],
        ["==", "map_type", "small-map"],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-section-invisible",
      interactive: true,
    },
    {
      paint: {
        "fill-color": "#E7E6E8",
      },
      filter: [
        "all",
        ["==", "object_type", "object"],
        [
          "!in",
          "secondary_type",
          "poi",
          "group-label",
          "field-label",
          "building-detail",
          "level-label",
          "overlap-label",
        ],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-object",
    },
    {
      paint: {
        "fill-opacity": 0,
      },
      filter: ["==", "object_type", "category"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-category",
      interactive: true,
    },
    {
      paint: {
        "fill-color": {
          default: "#E7E6E8",
          property: "supporter_section",
          type: "categorical",
          stops: [
            ["home", "#CFDDEB"],
            ["visitors", "#ebd6cf"],
          ],
        },
      },
      filter: ["==", "object_type", "row"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-row",
      interactive: true,
    },
    {
      paint: {
        "fill-color": "#E7E6E8",
        "fill-antialias": false,
      },
      filter: ["==", "object_type", "seat"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-seat",
    },
    {
      paint: {
        "line-color": "#FFF",
        "line-width": {
          base: 4,
          stops: [
            [5, 0.75],
            [7, 1.5],
          ],
        },
      },
      filter: ["==", "object_type", "seat"],
      source: "buster",
      minzoom: 5,
      "source-layer": "shape",
      type: "line",
      id: "map-shapes-seat-stroke",
    },
    {
      paint: {
        "line-color": "#FFF",
        "line-opacity": {
          stops: [
            [4.9, 0],
            [5, 1],
          ],
        },
        "line-width": {
          base: 4,
          stops: [
            [5, 0.75],
            [7, 1.5],
          ],
        },
      },
      filter: ["==", "object_type", "row"],
      source: "buster",
      minzoom: 4,
      "source-layer": "shape",
      type: "line",
      id: "map-shapes-row-stroke",
    },
    {
      paint: {
        "line-color": {
          default: "#AEADAF",
          property: "supporter_section",
          type: "categorical",
          stops: [
            ["home", "#7ca5be"],
            ["visitors", "#b98373"],
          ],
        },
        "line-width": {
          stops: [
            [3, 0.5],
            [7, 4],
          ],
        },
      },
      filter: [
        "all",
        ["==", "object_type", "section"],
        ["!=", "map_type", "small-map"],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "line",
      id: "map-shapes-section-stroke",
    },
    {
      paint: {
        "fill-color": "#BBBBBB",
      },
      filter: [
        "all",
        ["==", "object_type", "object"],
        ["==", "secondary_type", "building-detail"],
      ],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "map-shapes-building-detail",
    },
    {
      filter: ["==", "mk", "NONE"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "hovered-filter-fill",
    },
    {
      filter: ["==", "mk", "NONE"],
      source: "buster",
      "source-layer": "shape",
      type: "fill",
      id: "selected-filter-fill",
    },
    {
      layout: {
        "text-size": {
          stops: [
            [5, 6],
            [7, 12],
          ],
        },
        "text-field": "{label}",
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": 0.75,
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      source: "buster",
      minzoom: 5,
      "source-layer": "seat-labels",
      type: "symbol",
      id: "seat-labels",
    },
    {
      layout: {
        "text-size": {
          stops: [
            [5, 6],
            [7, 12],
          ],
        },
        "text-field": "{label}",
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": 0.75,
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
        "text-opacity": {
          stops: [
            [4.9, 0],
            [5, 1],
          ],
        },
      },
      source: "buster",
      minzoom: 4,
      "source-layer": "row-labels",
      type: "symbol",
      id: "row-labels",
    },
    {
      filter: [
        "all",
        ["any", ["<", "short_label_length", 7], [">", "way_area", 15]],
        ["==", "orientation", 0],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "section-labels",
      layout: {
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "section-labels",
    },
    {
      filter: [
        "all",
        ["any", ["<", "short_label_length", 7], [">", "way_area", 15]],
        ["!=", "orientation", 0],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "section-labels",
      layout: {
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-rotation-alignment": "map",
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "section-labels-aligned",
    },
    {
      filter: [
        "all",
        [">=", "short_label_length", 7],
        [">", "way_area", 15],
        ["==", "orientation", 0],
      ],
      source: "buster",
      minzoom: 4,
      "source-layer": "section-labels",
      layout: {
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "long-section-labels",
    },
    {
      filter: [
        "all",
        [">=", "short_label_length", 7],
        [">", "way_area", 15],
        ["!=", "orientation", 0],
      ],
      source: "buster",
      minzoom: 4,
      "source-layer": "section-labels",
      layout: {
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-rotation-alignment": "map",
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "long-section-labels-aligned",
    },
    {
      filter: [
        "all",
        [">", "short_label_length", 7],
        ["<", "way_area", 15],
        ["==", "orientation", 0],
      ],
      source: "buster",
      minzoom: 5,
      "source-layer": "section-labels",
      layout: {
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "small-section-labels",
    },
    {
      filter: [
        "all",
        [">", "short_label_length", 7],
        ["<", "way_area", 15],
        ["!=", "orientation", 0],
      ],
      source: "buster",
      minzoom: 5,
      "source-layer": "section-labels",
      layout: {
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-size": {
          stops: [
            [3, 8],
            [7, 22],
          ],
        },
        "text-rotation-alignment": "map",
        "text-field": "{short_label}",
        "text-max-width": 7,
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "small-section-labels-aligned",
    },
    {
      filter: [
        "all",
        ["!=", "secondary_type", "poi"],
        ["!=", "secondary_type", "field-label"],
        ["==", "orientation", 0],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-size": {
          stops: [
            [3, 6],
            [7, 18],
          ],
        },
        "text-field": "{label}",
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "generic-object-labels",
    },
    {
      filter: [
        "all",
        ["!=", "secondary_type", "poi"],
        ["!=", "secondary_type", "field-label"],
        ["!=", "orientation", 0],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-size": {
          stops: [
            [3, 6],
            [7, 18],
          ],
        },
        "text-rotation-alignment": "map",
        "text-field": "{label}",
      },
      paint: {
        "text-color": "#514C47",
        "text-halo-width": {
          stops: [
            [3, 1],
            [7, 2],
          ],
        },
        "text-halo-color": "rgba(255, 255, 255, 0.5)",
      },
      type: "symbol",
      id: "generic-object-labels-aligned",
    },
    {
      filter: ["all", ["==", "secondary_type", "overlap-label"]],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-size": {
          stops: [
            [3, 6],
            [7, 18],
          ],
        },
        "text-field": "{label}",
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "#514C47",
      },
      type: "symbol",
      id: "overlap-labels",
    },
    {
      filter: [
        "all",
        ["==", "secondary_type", "field-label"],
        ["in", "field_type", "mlb-baseball", "concert", "theater"],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-keep-upright": true,
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-allow-overlap": true,
        "text-field": "{label}",
        "text-rotation-alignment": "map",
        "text-size": {
          stops: [
            [3, 4],
            [7, 28],
          ],
        },
        "text-max-width": 40,
      },
      paint: {
        "text-color": {
          property: "field_type",
          type: "categorical",
          stops: [
            ["theater", "#4D4D4D"],
            ["mlb-baseball", "rgba(255, 255, 255, 0.75)"],
            ["concert", "#CCCCCC"],
          ],
        },
      },
      type: "symbol",
      id: "object-labels-field",
    },
    {
      filter: [
        "all",
        ["==", "secondary_type", "field-label"],
        ["==", "field_type", "nhl-hockey"],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-keep-upright": true,
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-allow-overlap": true,
        "text-field": "{label}",
        "text-rotation-alignment": "map",
        "text-size": {
          stops: [
            [3, 2],
            [9, 32],
          ],
        },
        "text-max-width": 40,
      },
      paint: {
        "text-color": "#777777",
      },
      type: "symbol",
      id: "object-labels-nhl",
    },
    {
      filter: [
        "all",
        ["==", "secondary_type", "field-label"],
        ["==", "field_type", "nfl-football"],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-keep-upright": true,
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-allow-overlap": true,
        "text-field": "{label}",
        "text-rotation-alignment": "map",
        "text-size": {
          stops: [
            [3, 6],
            [7, 32],
          ],
        },
        "text-max-width": 40,
      },
      paint: {
        "text-color": "#FFFFFF",
      },
      type: "symbol",
      id: "object-labels-nfl",
    },
    {
      filter: [
        "all",
        ["==", "secondary_type", "field-label"],
        ["==", "field_type", "mlb-baseball"],
      ],
      source: "buster",
      minzoom: 2,
      "source-layer": "object-labels",
      layout: {
        "text-keep-upright": true,
        "text-rotate": {
          property: "orientation",
          type: "identity",
        },
        "text-allow-overlap": true,
        "text-field": "{label}",
        "text-rotation-alignment": "map",
        "text-size": {
          stops: [
            [3, 2],
            [9, 32],
          ],
        },
        "text-max-width": 40,
      },
      paint: {
        "text-color": "rgba(255, 255, 255, 0.75)",
      },
      type: "symbol",
      id: "object-labels-mlb",
    },
    {
      layout: {
        "icon-image": "ds-normal-7",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 7]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-7",
    },
    {
      layout: {
        "icon-image": "ds-normal-6",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 6]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-6",
    },
    {
      layout: {
        "icon-image": "ds-normal-5",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 5]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-5",
    },
    {
      layout: {
        "icon-image": "ds-normal-4",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 4]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-4",
    },
    {
      layout: {
        "icon-image": "ds-normal-3",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 3]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-3",
    },
    {
      layout: {
        "icon-image": "ds-normal-2",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 2]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-2",
    },
    {
      layout: {
        "icon-image": "ds-normal-1",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 1]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-1",
    },
    {
      layout: {
        "icon-image": "ds-normal-0",
        "icon-allow-overlap": true,
      },
      filter: ["all", ["==", "detail", "row"], ["==", "bucket", 0]],
      source: "listings",
      minzoom: 5,
      type: "symbol",
      id: "row-markers-0",
    },
    {
      layout: {
        "icon-image": "ds-normal-7",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 7]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-7",
    },
    {
      layout: {
        "icon-image": "ds-normal-6",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 6]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-6",
    },
    {
      layout: {
        "icon-image": "ds-normal-5",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 5]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-5",
    },
    {
      layout: {
        "icon-image": "ds-normal-4",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 4]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-4",
    },
    {
      layout: {
        "icon-image": "ds-normal-3",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 3]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-3",
    },
    {
      layout: {
        "icon-image": "ds-normal-2",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 2]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-2",
    },
    {
      layout: {
        "icon-image": "ds-normal-1",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 1]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-1",
    },
    {
      layout: {
        "icon-image": "ds-normal-0",
        "icon-allow-overlap": true,
      },
      paint: {
        "icon-opacity": 0,
      },
      filter: ["all", ["==", "detail", "section"], ["==", "bucket", 0]],
      source: "listings",
      maxzoom: 5,
      type: "symbol",
      id: "section-markers-0",
    },
  ],
  sprite: "https://seatgeek.com/images/map/markers_5",
  transition: {
    duration: 300,
    delay: 0,
  },
  sources: {
    "raster-tiles": {
      tiles: [
        "https://seatgeek-tileserver.global.ssl.fastly.net/mapbox_tiles/field/437/{z}/{x}/{y}.png",
      ],
      type: "raster",
      bounds: [
        -8.294921875, -10.449061826881419, 16.99609375, 5.56547355071028,
      ],
      tileSize: 256,
    },
    listings: {
      data: {
        type: "FeatureCollection",
        features: [],
      },
      promoteId: "mk",
      type: "geojson",
    },
    buster: {
      tiles: [
        "https://seatgeek-tileserver.global.ssl.fastly.net/normal_350/437/{z}/{x}/{y}.mvt",
      ],
      promoteId: {
        shape: "mk",
      },
      type: "vector",
      bounds: [-22.4, -21.8, 22.4, 21.8],
    },
  },
  version: 8,
  glyphs:
    "https://seatgeek.com/images/map/glyphs.pbf?fontstack={fontstack}&range={range}",
  metadata: {
    seat_scale: 1,
    switch_level: 5,
  },
};

export default seatGeek;
