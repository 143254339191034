import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Heading,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcAreaChart } from "react-icons/fc";
import { GiBasketballJersey } from "react-icons/gi";

import { Sidebar } from "./Sidebar";

const Feature = ({ title, text, icon, link }) => {
  return (
    <Stack>
      <Card
        bg={useColorModeValue("white", "gray.900")}
        // border="2px solid"
        // borderColor={useColorModeValue("black")}
        // bg="red.500"
        // color="white"
      >
        <CardHeader>
          {/* <Flex
            w={16}
            h={16}
            align={"center"}
            justify={"center"}
            color={"white"}
            rounded={"full"}
            bg={"gray.100"}
            mb={1}
          >
            {icon}
          </Flex> */}
          <Heading size="md">{title}</Heading>
        </CardHeader>
        <CardBody>
          <Text>{text}</Text>
        </CardBody>
        <CardFooter>
          <Link to={link}>
            <Button colorScheme="black" variant="outline">
              View
            </Button>
          </Link>
        </CardFooter>
      </Card>
    </Stack>
  );
};

const Home = () => {
  return (
    <Sidebar
      children={
        <Box h="100%" p={3}>
          <Heading
            size="lg"
            // textAlign="center"
            color={useColorModeValue("red.600", "white")}
            // className="md-outline"
            fontFamily="Overpass"
            letterSpacing="3px"
            textDecoration="underline"
            p={3}
          >
            Features
          </Heading>
          <SimpleGrid p={2} columns={{ base: 1, md: 3 }} spacing={10}>
            <Feature
              icon={<Icon as={FcAreaChart} w={10} h={10} />}
              title={"Tracking TerpTix"}
              text={
                "Track the number of available tickets throughout the basketball or football season."
              }
              link="/ticket-tracker"
            />
            {/* <Feature
              icon={<Icon as={GiBasketballJersey} w={10} h={10} />}
              title={"Uniform Database"}
              text={
                "Explore the uniforms worn throughout Maryland basketball & football history."
              }
              link="/uniform-db"
            /> */}
          </SimpleGrid>
        </Box>
      }
    />
  );
};

export default Home;
