import React, { useState, useRef, useMemo, useCallback } from "react";
import {
  useColorModeValue,
  SimpleGrid,
  Card,
  CardBody,
  CardHeader,
  Text,
} from "@chakra-ui/react";

import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import L, { divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
// import "../../css/legend.css";

import xfinitySections from "../../geo/xfinity_center.json";
import { SectionChart } from "./Charts";
import { useEffect } from "react";

const Legend = () => {
  return (
    <div className="legend">
      <div style={{ "--color": "#a50f15" }}>1</div>
      <div style={{ "--color": "#de2d26" }}>2</div>
      <div style={{ "--color": "#fb6a4a" }}>3</div>
      <div style={{ "--color": "#fc9272" }}>4</div>
      <div style={{ "--color": "#fcbba1" }}>5</div>
      <div style={{ "--color": "#fee5d9" }}>6</div>
    </div>
  );
};

const SectionControl = ({ section, count, total }) => {
  return section ? (
    <div className="leaflet-top leaflet-right">
      <div
        className="leaflet-control leaflet-bar"
        style={{ backgroundColor: "white", padding: "10px" }}
      >
        <div>Section: {section}</div>
        {count > -1 ? <div>Available: {count}</div> : null}
        {total ? <div>Capacity: {total}</div> : null}
        {count > -1 ? (
          <div>
            % Available: {Math.round((count / total) * 100 * 100) / 100}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export const XfinitySections = ({ ticketData }) => {
  const [onselect, setOnselect] = useState({});
  const [sectionData, setSectionData] = useState({});
  const [latest, setLatest] = useState([]);
  const [section, setSection] = useState(null);
  const sdRef = useRef();
  sdRef.current = sectionData;

  const highlightFeature = (e) => {
    var layer = e.target;
    const { section } = e.target.feature.properties;

    if (section != "MEDIA") {
      try {
        let tc = 0;
        let cn = 0;
        var sect = sdRef.current[section];

        var subSections = sect["pl"];
        subSections.forEach((sub) => {
          tc += sub["tc"];
          cn += sub["cn"];
        });
        let d = (cn / tc) * 100;
        setOnselect({
          section: section,
          count: cn,
          total: tc,
        });
      } catch (err) {
        setOnselect({
          section: section,
        });
        // console.log(err);
      }
    } else {
      setOnselect({
        section: "MEDIA",
      });
    }

    layer.setStyle({
      weight: 2,
      color: "#666",
    });
    layer.bringToFront();
  };

  const resetHighlight = (e) => {
    setOnselect({});
    // e.target.setStyle(style(e.target.feature));
  };

  const clickFeature = (e) => {
    let thisSection = e.target.feature.properties.section;
    let properties = { section_name: thisSection };
    if (thisSection != "MEDIA") {
      try {
        let tc = 0;
        let cn = 0;
        var sect = sdRef.current[thisSection];

        var subSections = sect["pl"];
        subSections.forEach((sub) => {
          tc += sub["tc"];
          cn += sub["cn"];
        });
        let d = (cn / tc) * 100;
        let total = tc;
        let available = cn;
        let percentage = Math.round((available / total) * 10000) / 100;
        properties["total"] = total;
        properties["available"] = available;
        properties["percentage"] = percentage;
      } catch (err) {
        // console.log(err);
      }
    }

    setSection(properties);
  };

  const getColor = (section) => {
    if (section != "MEDIA") {
      try {
        let tc = 0;
        let cn = 0;
        var sect = sectionData[section];

        var subSections = sect["pl"];
        subSections.forEach((sub) => {
          tc += sub["tc"];
          cn += sub["cn"];
        });
        let d = (cn / tc) * 100;
        // console.log(section, cn, tc, d);

        return d > 80
          ? "#313695"
          : d > 70
          ? "#4575b4"
          : d > 60
          ? "#74add1"
          : d > 50
          ? "#abd9e9"
          : d > 40
          ? "#e0f3f8"
          : d > 30
          ? "#fee090"
          : d > 20
          ? "#fdae61"
          : d > 10
          ? "#f46d43"
          : d > 0.1
          ? "#d73027"
          : "gray";
      } catch (err) {
        // console.log(err);
        return "black";
      }
    } else {
      return "black";
    }
  };

  const style = (feature) => {
    // console.log(feature.properties.section, onselect["section"]);
    if (feature.properties.section != onselect["section"]) {
      return {
        fillColor: getColor(feature.properties.section),
        weight: 0,
        opacity: 1,
        fillOpacity: 1,
        color: "black",
      };
    }
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: clickFeature,
    });
  };

  useEffect(() => {
    if (ticketData.length > 0) {
      let l = ticketData[ticketData.length - 1];

      setLatest(l);
    }
  }, [ticketData]);

  useEffect(() => {
    setSectionData(latest.data);
  }, [latest]);

  const xfinitySection = [xfinitySections].map((xSection) => {
    return xSection;
  });

  return (
    <SimpleGrid h="full" columns={{ base: 1, lg: 2 }} spacing={3}>
      <Card
        h="full"
        p={1}
        elevation={3}
        bg={useColorModeValue("white", "gray.900")}
      >
        <MapContainer
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
          }}
          center={[0, 0]}
          zoom={4}
          minZoom={4}
          attributionControl={false}
        >
          <>
            <GeoJSON
              data={xfinitySections}
              style={style}
              onEachFeature={onEachFeature}
            />
            {onselect ? (
              <SectionControl
                section={onselect["section"]}
                count={onselect["count"]}
                total={onselect["total"]}
              />
            ) : null}
          </>
        </MapContainer>
      </Card>
      <Card elevation={3} h="full" bg={useColorModeValue("white", "gray.900")}>
        <CardHeader>
          {section ? (
            <>
              <Text variant="h6">
                {section.section_name} - {section.percentage}% Available
              </Text>
            </>
          ) : (
            <Text variant="h6">
              Click a section on the map to see detailed availability.
            </Text>
          )}
        </CardHeader>
        {section ? (
          <CardBody sx={{ height: "70%" }}>
            <SectionChart ticketData={ticketData} section={section} />
          </CardBody>
        ) : null}
      </Card>
    </SimpleGrid>
  );
};
