import React, { useState, useEffect } from "react";
import moment from "moment";

import { Link } from "react-router-dom";

import {
  Box,
  Flex,
  Text,
  Card,
  CardHeader,
  CardBody,
  Alert,
  AlertTitle,
  AlertDescription,
  VStack,
  Image,
  HStack,
  Heading,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";

import { AlertIcon } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

export const CurrentAvailability = ({ latest, games, game }) => {
  let [currentCount, setCurrentCount] = useState(0);
  let [currentTotal, setCurrentTotal] = useState(0);
  let [currentDate, setCurrentDate] = useState(null);
  let [currentGame, setCurrentGame] = useState(null);

  const terpsURL =
    "https://storage.googleapis.com/marylandwillwin-edb5b.appspot.com/img/opponents/terps.png";

  const terpsHoopsURL =
    "https://storage.googleapis.com/marylandwillwin-edb5b.appspot.com/img/opponents/hoops-script.png";

  useEffect(() => {
    if (latest) {
      console.log(latest);
      tallyData(latest);
    }
  }, [latest]);

  useEffect(() => {
    let thisGame = games.find((g) => g.id == game);
    console.log(thisGame);
    setCurrentGame(thisGame);
  }, [games, game]);

  const tallyData = (latest) => {
    let tc = 0;
    let cn = 0;
    for (const section in latest.data) {
      var sect = latest.data[section];
      var subSections = sect["pl"];
      subSections.forEach((sub) => {
        tc += sub["tc"];
        cn += sub["cn"];
      });
    }
    setCurrentCount(cn);
    setCurrentTotal(tc);
    setCurrentDate(
      moment(latest.dateTime.seconds * 1000).format("YYYY-MM-DD LT")
    );
  };

  return (
    <Card bg={useColorModeValue("white", "gray.900")}>
      <CardHeader>
        <VStack>
          <HStack>
            <Image
              src={
                currentGame
                  ? currentGame.sport == "fb"
                    ? terpsURL
                    : terpsHoopsURL
                  : null
              }
              h={currentGame ? (currentGame.sport == "fb" ? 8 : 12) : 8}
            />{" "}
            <Heading size="md">
              vs. {currentGame ? currentGame.opponent : null}
            </Heading>
          </HStack>
          <Text>
            {currentGame
              ? moment(currentGame.date.seconds * 1000).format(
                  "dddd, MMMM Do, YYYY"
                )
              : null}
          </Text>
        </VStack>
      </CardHeader>
      <CardBody py={0}>
        <Text
          textAlign="center"
          fontSize="lg"
          // textTransform="uppercase"
          color="red"
        >
          <span style={{ fontWeight: "bold", fontFamily: "monospace" }}>
            {currentCount.toLocaleString("en-US")}
          </span>{" "}
          of{" "}
          <span style={{ fontWeight: "bold", fontFamily: "monospace" }}>
            {currentTotal.toLocaleString("en-US")}
          </span>{" "}
          Tickets Available for Purchase.
        </Text>
        <Text
          // w="100%"
          fontFamily="monospace"
          textAlign="center"
          fontWeight="bold"
        >
          Updated: <span>{currentDate}</span>
        </Text>
        <br />

        <Alert borderRadius={5} mb={3} status="info">
          <VStack>
            <Text w="fit-content" fontSize="xs">
              The data used in this tool represents the number of tickets
              available for purchase from{" "}
              <a
                style={{
                  color: useColorModeValue("navy", "lightblue"),
                  textDecoration: "underline",
                }}
                href="https://umterps.com"
                target="_blank"
              >
                umterps.com
              </a>{" "}
              at a given point in time. These values may fluctuate throughout
              the season as tickets are added or removed from the online ticket
              inventory. This data does not include student allotment or other
              sections unavailable to the general public.{" "}
            </Text>
            <Link to="/ticket-tracker/about">
              <Button size="sm" colorScheme="blue" leftIcon={<InfoIcon />}>
                <Text overflowWrap="break-word" mt={1}>
                  More Info
                </Text>
              </Button>
            </Link>
          </VStack>
        </Alert>
      </CardBody>
      {/* <CardActions>
        <Button size="small">About This Data</Button>
      </CardActions> */}
    </Card>
  );
};
