import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
  HStack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import { Sidebar } from "../Sidebar";

const AboutTerpTix = () => {
  return (
    <Sidebar
      children={
        <Box overflow="auto" h="90%" p={3} py={0}>
          <Box textAlign="left" px={3}>
            <HStack>
              {/* <InfoIcon boxSize={"40px"} color={"red.600"} /> */}
              <Heading
                color={useColorModeValue("red.500", "white")}
                as="h2"
                size="lg"
                mt={6}
                mb={2}
                ml={2}
              >
                About Tracking TerpTix
              </Heading>
            </HStack>
            <Text p={2}>
              The decline in attendance at Maryland Football games since the
              Ralph Friedgen era has been{" "}
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                target="_blank"
                href="https://www.washingtonpost.com/sports/2022/11/18/maryland-football-attendance-trouble/"
              >
                well documented
              </a>
              . Many are interested in seeing this trend reversed. This tool
              tracks the number of tickets available for purchase online from{" "}
              <a
                style={{ color: "blue", textDecoration: "underline" }}
                target="_blank"
                href="https://umterps.com"
              >
                umterps.com
              </a>{" "}
              as a possible indicator of a future game's attendance.
            </Text>
            <Accordion
              h="100%"
              bg={useColorModeValue("white", "gray.900")}
              defaultIndex={[0]}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                      How does it work?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>
                    When purchasing tickets online from{" "}
                    <a
                      style={{ color: "blue", textDecoration: "underline" }}
                      target="_blank"
                      href="https://umterps.com"
                    >
                      umterps.com
                    </a>{" "}
                    by seat, ticket data for each section is provided to
                    populate the seating chart. This tool simply makes a request
                    to the same source of this information, stores the results
                    in a database, and displays the data in some charts.
                  </Text>
                  <br />
                  <Text>
                    This data is only a snapshot of the online ticket inventory
                    made available by the ticket office and does not necessarily
                    represent the number of unsold tickets. The number of
                    available tickets listed may fluctuate throughout the season
                    leading up to a game. For example, a previous version of
                    this tool used for the 2022 season regularly saw additional
                    tickets added to the ticket inventory prior to a game.
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                      What data does it include?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  The data used in this tool is just one piece of the attendance
                  puzzle. It does not include data on student tickets, or other
                  tickets that may not be available for purchase online for the
                  general public. It also does not include data from ticket
                  resellers like StubHub or SeatGeeek.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                      How can I help?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Buy tickets from Maryland{" "}
                  <a
                    style={{ color: "blue", textDecoration: "underline" }}
                    target="_blank"
                    href="https://umterps.com"
                  >
                    here
                  </a>
                  !
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                      Who are you?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  I am just a Maryland fan who would like to see more fans with
                  me at games!
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Box>
      }
    />
  );
};

export default AboutTerpTix;
