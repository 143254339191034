const mdSections = {
  type: "FeatureCollection",
  name: "maryland_stadium_sections",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { section_name: "B:28" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [11.407444030793094, -9.938215447677354],
              [11.405776235179539, -15.09659400180467],
              [16.135644595226658, -15.09659400180467],
              [16.133420867741911, -9.940542696037422],
              [11.407444030793094, -9.938215447677354],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:27" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.47045595531956, -9.936161979487174],
              [11.183403486705304, -9.936161979487174],
              [11.196745851613768, -15.10088792331168],
              [8.48379832022802, -15.083711716516461],
              [8.47045595531956, -9.936161979487174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:26" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.254754389299551, -15.083711716516461],
              [8.263649299238519, -9.927400370386126],
              [5.550701767852774, -9.936161979487174],
              [5.559596677791744, -15.092299993488222],
              [8.254754389299551, -15.083711716516461],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:25" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [5.328329019378534, -9.927400370386126],
              [5.328329019378534, -15.092299993488222],
              [2.633171307870728, -15.092299993488222],
              [2.624276397931758, -9.918638526783186],
              [5.328329019378534, -9.927400370386126],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:24" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.419693469335456, -9.918638526783186],
              [2.401903649457517, -15.100887923311657],
              [-0.302148971989258, -15.092299993488222],
              [-0.302148971989258, -9.94492335388774],
              [2.419693469335456, -9.918638526783186],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:23" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.210784522032337, -15.118062740798075],
              [-3.219679431971306, -9.936161979487174],
              [-0.497836990646591, -9.927400370386126],
              [-0.506731900585561, -15.092299993488222],
              [-3.210784522032337, -15.118062740798075],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:22" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.785183581229963, -9.778417299044216],
              [-3.442052180445545, -9.936161979487174],
              [-3.442052180445545, -15.092299993488222],
              [-5.897047323601171, -14.860295253176618],
              [-4.785183581229963, -9.778417299044216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:21" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-4.998661419765241, -9.752119207308267],
              [-6.10163025219748, -14.817303922679235],
              [-8.449886476085471, -14.076536244582357],
              [-6.297318270854812, -9.348625222026646],
              [-4.998661419765241, -9.752119207308267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:20" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.475216469634204, -9.269625355579542],
              [-8.636679584803833, -13.998872774903333],
              [-10.780352880095521, -12.796152142381914],
              [-7.66713440145614, -8.584230672558052],
              [-6.475216469634204, -9.269625355579542],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:19" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.81834787041862, -8.461077086155244],
              [-10.949356168935941, -12.666008725390867],
              [-12.790602526302656, -11.055641161029033],
              [-8.83236760346116, -7.518571676430793],
              [-7.81834787041862, -8.461077086155244],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:18" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.775228056991946, -6.291170754241723],
              [-8.974686162484675, -7.403916947390552],
              [-12.924026175387205, -10.898462253569678],
              [-14.365001585500288, -8.918301732173429],
              [-9.775228056991946, -6.291170754241723],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:17" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-14.471740504767924, -8.75130368331995],
              [-15.494655147749439, -6.520994237954691],
              [-10.424556482536724, -4.874773004659459],
              [-9.873072066320606, -6.114314080118837],
              [-14.471740504767924, -8.75130368331995],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:16" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-16.04613956396555, -3.908085796651968],
              [-15.574709337200158, -6.300012022492223],
              [-10.51350558192642, -4.679764905831391],
              [-10.798142699973452, -3.366591315764631],
              [-16.04613956396555, -3.908085796651968],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:15" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-16.07282429378245, -1.233656473602459],
              [-16.108403933538334, -3.668448155162406],
              [-10.807037609912415, -3.135696030939803],
              [-10.789247790034477, -1.802728530939957],
              [-16.07282429378245, -1.233656473602459],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:14" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-15.565814427261186, 1.399002501948007],
              [-16.072824293782457, -1.020220050369514],
              [-10.771457970156535, -1.598235807259929],
              [-10.522400491865389, -0.228626375311626],
              [-15.565814427261186, 1.399002501948007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:13" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-14.471740504767917, 3.842358496902671],
              [-15.494655147749427, 1.603515098658893],
              [-10.424556482536723, -0.050728776618738],
              [-9.873072066320605, 1.203365038466721],
              [-14.471740504767917, 3.842358496902671],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:12" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.775228056991937, 1.381217917825865],
              [-8.983581072423636, 2.483542472822967],
              [-12.924026175387196, 6.022270099350449],
              [-14.373896495439249, 4.0375839285396],
              [-9.775228056991937, 1.381217917825865],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:11" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-10.949356168935928, 7.79708865732523],
              [-7.845032600235521, 3.531681402992733],
              [-8.832367603461154, 2.652375984078635],
              [-12.755022886546771, 6.17262806032956],
              [-10.949356168935928, 7.79708865732523],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:10" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-6.488558834542648, 4.341420170818977],
              [-7.660463219001902, 3.667061172162308],
              [-10.767010515187057, 7.924852816498495],
              [-8.658916859651248, 9.156306355939931],
              [-6.488558834542648, 4.341420170818977],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:9" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-8.42764920123804, 9.235331676694512],
              [-6.09718279722799, 9.972027367557567],
              [-4.994213964795751, 4.857878203218236],
              [-6.275080996007382, 4.432325879295566],
              [-8.42764920123804, 9.235331676694512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:8" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.883704958692716, 10.033345291345391],
              [-3.419814905598121, 10.287252268903421],
              [-3.43760472547606, 5.043974300353944],
              [-4.789631036199449, 4.911053845332092],
              [-5.883704958692716, 10.033345291345391],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:7" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.224126886940789, 10.287252268903421],
              [-0.520074265494013, 10.287252268903421],
              [-0.520074265494013, 5.026253129725903],
              [-3.224126886940789, 5.026253129725903],
              [-3.224126886940789, 10.287252268903421],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:6" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.406351104427006, 10.269747939004612],
              [2.406351104427006, 5.03511377540404],
              [-0.288806607080801, 5.043974300353944],
              [-0.288806607080801, 10.296004069990868],
              [2.406351104427006, 10.269747939004612],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:5" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [5.332776474348024, 10.278500225175499],
              [5.332776474348024, 5.017392363529795],
              [2.628723852901248, 5.026253129725903],
              [2.610934033023308, 10.278500225175499],
              [5.332776474348024, 10.278500225175499],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:4" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.268096754208013, 5.03511377540404],
              [5.546254312883298, 5.026253129725903],
              [5.546254312883298, 10.278500225175499],
              [8.268096754208013, 10.269747939004612],
              [8.268096754208013, 5.03511377540404],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:3" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.490469502682259, 10.313506943454536],
              [11.212311944006972, 10.27850022517551],
              [11.176732304251098, 5.043974300353944],
              [8.472679682804321, 5.043974300353944],
              [8.490469502682259, 10.313506943454536],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "B:2" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [11.390210142786369, 10.296004069990868],
              [16.140092050196166, 10.296004069990868],
              [16.122302230318226, 5.043974300353944],
              [11.407999962664308, 5.026253129725903],
              [11.390210142786369, 10.296004069990868],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:212" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-9.414984204463666, 10.505973990966218],
              [-11.016067993478204, 9.630189581315424],
              [-11.620921869328139, 10.567188477825505],
              [-9.877519521290088, 11.518769576073495],
              [-9.414984204463666, 10.505973990966218],
            ],
          ],
          [
            [
              [-8.934659067759302, 9.489847555390002],
              [-10.402319207689299, 8.708168598526218],
              [-10.891539254332629, 9.463527000995569],
              [-9.29935037525706, 10.322258015435903],
              [-8.934659067759302, 9.489847555390002],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:211" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-7.431419288073433, 11.03026898695127],
              [-7.164571989904341, 10.138434687663741],
              [-8.738971049101972, 9.586338922964748],
              [-9.103662356599727, 10.401006685235197],
              [-7.431419288073433, 11.03026898695127],
            ],
          ],
          [
            [
              [-7.484788747707248, 11.231003514444623],
              [-9.192611455989423, 10.593419527662173],
              [-9.681831502632756, 11.605913639993499],
              [-7.805005505510157, 12.284688433524618],
              [-7.484788747707248, 11.231003514444623],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:210" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.48788146640856, 11.658187028606484],
              [-7.284653274080429, 11.27462307091915],
              [-7.59597512194437, 12.341175410171349],
              [-5.647989845310014, 12.753591753745614],
              [-5.48788146640856, 11.658187028606484],
            ],
          ],
          [
            [
              [-5.332220542476589, 10.567188477825512],
              [-6.959989061308037, 10.225982878419627],
              [-7.217941449538158, 11.082647837352898],
              [-5.447854371683195, 11.449034861593418],
              [-5.332220542476589, 10.567188477825512],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:209" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.442052180445542, 12.914036667223529],
              [-3.437604725476056, 11.823654322554534],
              [-5.269956172903806, 11.693030491230612],
              [-5.425617096835775, 12.783954037868613],
              [-3.442052180445542, 12.914036667223529],
            ],
          ],
          [
            [
              [-3.433157270506572, 11.59720045498767],
              [-3.442052180445542, 10.707061390589457],
              [-5.118742703941321, 10.606534210732162],
              [-5.234376533147928, 11.479545920623218],
              [-3.433157270506572, 11.59720045498767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:208" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.513403083039785, 11.603735369246404],
              [-0.513403083039785, 10.709246394892963],
              [-3.221903159456046, 10.704876370530508],
              [-3.226350614425531, 11.590665387765981],
              [-0.513403083039785, 11.603735369246404],
            ],
          ],
          [
            [
              [-3.213008249517077, 12.924873840009734],
              [-0.51785053800927, 12.920539027320377],
              [-0.513403083039785, 11.812771374296682],
              [-3.217455704486561, 11.825830860291434],
              [-3.213008249517077, 12.924873840009734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:207" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.408574831911744, 12.924873840009743],
              [2.413022286881228, 11.812771374296682],
              [-0.299925244504517, 11.812771374296682],
              [-0.291030334565547, 12.920539027320377],
              [2.408574831911744, 12.924873840009743],
            ],
          ],
          [
            [
              [2.408574831911744, 11.595022116238965],
              [2.413022286881228, 10.713616356221506],
              [-0.291030334565547, 10.709246394892963],
              [-0.295477789535032, 11.599378776740483],
              [2.408574831911744, 11.595022116238965],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:206" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [5.330552746863277, 12.920539027320377],
              [5.330552746863277, 11.817124605502446],
              [2.630947580385985, 11.817124605502446],
              [2.63539503535547, 12.920539027320377],
              [5.330552746863277, 12.920539027320377],
            ],
          ],
          [
            [
              [5.335000201832761, 11.603735369246404],
              [5.335000201832761, 10.713616356221506],
              [2.630947580385985, 10.709246394892963],
              [2.630947580385985, 11.599378776740483],
              [5.335000201832761, 11.603735369246404],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:205" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.261425571753779, 12.924873840009743],
              [8.261425571753779, 11.817124605502446],
              [5.557372950307002, 11.821477767508718],
              [5.548478040368034, 12.920539027320377],
              [8.261425571753779, 12.924873840009743],
            ],
          ],
          [
            [
              [8.256978116784296, 11.603735369246404],
              [8.261425571753781, 10.713616356221506],
              [5.557372950307005, 10.717986254491615],
              [5.552925495337519, 11.599378776740483],
              [8.256978116784296, 11.603735369246404],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:204" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.483798320228017, 11.603735369246413],
              [8.479350865258532, 10.713616356221506],
              [11.187850941674794, 10.704876370530519],
              [11.183403486705309, 11.60809189373264],
              [8.483798320228017, 11.603735369246413],
            ],
          ],
          [
            [
              [11.178956031735824, 12.920539027320377],
              [11.192298396644281, 11.812771374296682],
              [8.479350865258535, 11.817124605502446],
              [8.48379832022802, 12.929208577438166],
              [11.178956031735824, 12.920539027320377],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:203" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [13.651740994769387, 11.603735369246413],
              [13.660635904708359, 10.717986254491626],
              [11.410223690149035, 10.713616356221506],
              [11.405776235179552, 11.60809189373264],
              [13.651740994769387, 11.603735369246413],
            ],
          ],
          [
            [
              [13.665083359677844, 12.924873840009743],
              [13.656188449738872, 11.804064704382753],
              [11.401328780210065, 11.817124605502446],
              [11.410223690149035, 12.920539027320377],
              [13.665083359677844, 12.924873840009743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:202" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [16.135644595226669, 11.601557081494409],
              [16.135644595226669, 10.707061390589439],
              [13.876337470728377, 10.711431383438004],
              [13.880784925697858, 11.601557081494409],
              [16.135644595226669, 11.601557081494409],
            ],
          ],
          [
            [
              [13.8830086531826, 12.916204139393592],
              [13.8830086531826, 11.819301195157026],
              [16.133420867741929, 11.812771374296682],
              [16.131197140257186, 12.914036667223519],
              [13.8830086531826, 12.916204139393592],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:312" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-12.350304484323647, 11.693030491230612],
              [-14.307184670896971, 14.637454228529124],
              [-11.923348807253101, 15.890270538685126],
              [-10.420109027567229, 12.692856266962357],
              [-12.350304484323647, 11.693030491230612],
            ],
          ],
          [
            [
              [-10.046522810130506, 11.901998753157878],
              [-11.861084437680315, 10.942950177978437],
              [-12.216880835239101, 11.501337514759454],
              [-10.331159928177534, 12.527929877327036],
              [-10.046522810130506, 11.901998753157878],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:311" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-8.178591722946877, 13.550451322281084],
              [-10.233315918848865, 12.788291209234071],
              [-11.727660788595768, 15.992904498612271],
              [-9.201506365928388, 16.888672034194926],
              [-8.178591722946877, 13.550451322281084],
            ],
          ],
          [
            [
              [-7.938429154594695, 12.692856266962366],
              [-9.850834791473169, 11.989021640714176],
              [-10.153261729398141, 12.614746480928014],
              [-8.125222263313058, 13.334170735109383],
              [-7.938429154594695, 12.692856266962366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:310" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-5.799203314272496, 13.835640212192853],
              [-5.714701669852284, 13.182658910913597],
              [-7.724951316059428, 12.766604607683568],
              [-7.907296969808306, 13.403401734792787],
              [-5.799203314272496, 13.835640212192853],
            ],
          ],
          [
            [
              [-5.834782954028395, 14.042831374597059],
              [-7.960666429442144, 13.610974783116081],
              [-8.992475982362622, 16.956749989749241],
              [-6.341792820549666, 17.500478778049896],
              [-5.834782954028395, 14.042831374597059],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:309" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.442052180445542, 14.016942666494383],
              [-3.442052180445542, 13.368788720034956],
              [-5.505671286286502, 13.221628103073293],
              [-5.585725475737229, 13.878820352753623],
              [-3.442052180445542, 14.016942666494383],
            ],
          ],
          [
            [
              [-6.128314982014393, 17.525926607243662],
              [-5.612410205554154, 14.085972717861146],
              [-3.415367450628648, 14.206725051466567],
              [-3.433157270506587, 17.712434682894958],
              [-6.128314982014393, 17.525926607243662],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:308" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.224126886940788, 17.695487380773134],
              [-0.520074265494012, 17.691250305269154],
              [-0.51785053800927, 14.226125683559239],
              [-3.219679431971302, 14.226125683559248],
              [-3.224126886940788, 17.695487380773134],
            ],
          ],
          [
            [
              [-3.217455704486561, 14.01047003293878],
              [-3.213008249517077, 13.353643962490676],
              [-0.51785053800927, 13.353643962490676],
              [-0.51785053800927, 14.01047003293878],
              [-3.217455704486561, 14.01047003293878],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:307" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-0.291030334565547, 14.014785142253618],
              [-0.295477789535032, 13.353643962490688],
              [2.413022286881228, 13.344989388582913],
              [2.404127376942259, 14.014785142253618],
              [-0.291030334565547, 14.014785142253618],
            ],
          ],
          [
            [
              [-0.302148971989263, 17.70607963212494],
              [2.410798559396484, 17.689131730035445],
              [2.419693469335453, 14.226125683559248],
              [-0.293254062050293, 14.226125683559248],
              [-0.302148971989263, 17.70607963212494],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:306" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.628723852901241, 17.693368855515924],
              [5.337223929317501, 17.693368855515924],
              [5.335000201832759, 14.228281206589415],
              [2.635395035355467, 14.232592190960732],
              [2.628723852901241, 17.693368855515924],
            ],
          ],
          [
            [
              [2.630947580385985, 14.014785142253618],
              [2.630947580385985, 13.353643962490676],
              [5.335000201832761, 13.349316714313842],
              [5.330552746863277, 14.014785142253618],
              [2.630947580385985, 14.014785142253618],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:305" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.261425571753778, 14.019100170453491],
              [8.261425571753778, 13.357971133090144],
              [5.557372950307001, 13.353643962490688],
              [5.552925495337518, 14.02341511751542],
              [8.261425571753778, 14.019100170453491],
            ],
          ],
          [
            [
              [8.259201844269032, 14.230436709057534],
              [5.555149222822255, 14.227203447644419],
              [5.557372950306998, 17.692309583516082],
              [8.256978116784289, 17.690191020775515],
              [8.259201844269032, 14.230436709057534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:304" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.483798320228011, 17.687013129817391],
              [11.187850941674787, 17.693368855515942],
              [11.183403486705304, 14.228281206589426],
              [8.482686456485641, 14.228281206589426],
              [8.483798320228011, 17.687013129817391],
            ],
          ],
          [
            [
              [11.187850941674794, 14.014785142253618],
              [11.183403486705309, 13.349316714313842],
              [8.483798320228017, 13.353643962490676],
              [8.474903410289047, 14.014785142253618],
              [11.187850941674794, 14.014785142253618],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:303" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [13.658412177223621, 14.01694266649436],
              [13.662859632193108, 13.347153061141192],
              [11.407999962664297, 13.347153061141192],
              [11.407999962664297, 14.008312447870413],
              [13.658412177223621, 14.01694266649436],
            ],
          ],
          [
            [
              [13.658412177223608, 14.227203447644436],
              [11.406888098921913, 14.227203447644436],
              [11.406888098921916, 17.694428121268405],
              [13.657300313481242, 17.692309583516082],
              [13.658412177223608, 14.227203447644436],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "V:302" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [16.131197140257179, 14.012627597734147],
              [16.135644595226669, 13.351480348097992],
              [13.885232380667345, 13.351480348097992],
              [13.880784925697858, 14.012627597734147],
              [16.131197140257179, 14.012627597734147],
            ],
          ],
          [
            [
              [16.133420867741926, 17.690191020775515],
              [13.88189678944023, 17.689131730035449],
              [13.882174755375825, 14.228820084134261],
              [16.132586969935147, 14.228281206589426],
              [16.133420867741926, 17.690191020775515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:28" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [16.115264726665288, -15.529019660231464],
              [11.419428544740992, -15.529019660231464],
              [11.432883662740775, -16.550577404397153],
              [16.128719844665071, -16.524780371027976],
              [16.115264726665288, -15.529019660231464],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:27" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.513123056787846, -15.535501527635345],
              [11.190691538744682, -15.529019660231464],
              [11.197419097744575, -16.531229952659093],
              [8.513123056787844, -16.537679318814],
              [8.513123056787846, -15.535501527635345],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:26" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [8.244020696792184, -15.509572835726319],
              [8.250748255792073, -16.518330573995179],
              [5.566452214835349, -16.518330573995186],
              [5.566452214835349, -15.509572835726315],
              [8.244020696792184, -15.509572835726319],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:25" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.639964049882535, -15.52253758906096],
              [5.330987649839151, -15.52253758906096],
              [5.351170326838825, -16.505430334022726],
              [2.633236490882642, -16.505430334022726],
              [2.639964049882535, -15.52253758906096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:24" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [2.397771925886434, -15.522537589060954],
              [2.404499484886325, -16.49252923333821],
              [-0.299979233070075, -16.486078360415384],
              [-0.299979233070075, -15.529019660231464],
              [2.397771925886434, -15.522537589060954],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:23" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.219739839023002, -15.522537589060954],
              [-0.508533562066711, -15.529019660231453],
              [-0.501806003066819, -16.479627272538316],
              [-3.219739839023002, -16.479627272538316],
              [-3.219739839023002, -15.522537589060954],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:22" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-3.468659522018987, -15.541983191196021],
              [-4.74689573199838, -15.477157391834581],
              [-6.025131941977774, -15.256598174434879],
              [-6.253868947974086, -16.046918640265947],
              [-4.867991793996428, -16.34410490496461],
              [-3.509024876018336, -16.434463644950231],
              [-3.468659522018987, -15.541983191196021],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:21" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-8.635424833935685, -14.463293105004791],
              [-6.240413829974298, -15.24361685382104],
              [-6.469150835970611, -16.021055265901591],
              [-8.951620106930587, -15.198175923569652],
              [-8.635424833935685, -14.463293105004791],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: { section_name: "M:20" },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-11.023708278897185, -13.130502972121372],
              [-8.837251603932433, -14.391623734386524],
              [-9.180357112926902, -15.113759664683954],
              [-11.521547644889157, -13.771714592534391],
              [-11.023708278897185, -13.130502972121372],
            ],
          ],
        ],
      },
    },
  ],
};
export default mdSections;
