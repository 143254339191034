import React, { useState, useEffect, useCallback } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/config";

import moment from "moment";
import {
  Link,
  Outlet,
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import {
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";

import { Sidebar } from "../Sidebar";

import { Sections } from "./Sections";
import { TotalChart } from "./Charts";
import { CurrentAvailability } from "./CurrentAvailability";

import { db, storage } from "../../firebase/config";

import {
  query,
  getDocs,
  collection,
  doc,
  getDoc,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

const imageURL =
  "https://storage.googleapis.com/marylandwillwin-edb5b.appspot.com/img/opponents/";

export const Football = () => {
  const [games, setGames] = useState([]);
  const [game, setGame] = useState(1);

  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);

  const params = useParams();

  const getGames = async (year) => {
    let since = new Date(parseInt(year), 7, 1);
    let before = new Date(parseInt(year) + 1, 7, 1);
    const q = query(
      collection(db, "games"),
      where("sport", "==", "fb"),
      where("date", ">=", since),
      where("date", "<", before),
      orderBy("date", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const games = [];
      querySnapshot.forEach((doc) => {
        games.push(doc.data());
      });
      setGames(games);
      if (params.gameID != undefined) {
        navigate(params.gameID);
      } else {
        let today = moment();
        let nearestGame;
        let nearestDate;
        games.forEach((game) => {
          let date = moment(game.date.seconds * 1000);
          let diff = date.diff(today, "days");
          if (diff > 0) {
            if (nearestDate) {
              if (moment(date).diff(moment(nearestDate), "days") < 0) {
                nearestDate = date;
                nearestGame = game.id;
              }
            } else {
              nearestDate = date;
              nearestGame = game.id;
            }
          } else {
            nearestDate = date;
            nearestGame = game.id;
          }
        });
        navigate(nearestGame);
      }
    });
  };

  useEffect(() => {
    if (params.gameID != undefined) {
      let selectedGame = games.find((game) => game.id == params.gameID);

      setGame(selectedGame);
    }
  }, [params]);

  useEffect(() => {
    if (params.year != undefined) {
      console.log(params.year);
      getGames(params.year);
      console.log("hi");
    } else {
      getGames(2023);
    }
    if (params.gameID != undefined) {
      let selectedGame = games.find((game) => game.id == params.gameID);
      setGame(selectedGame);
    }
  }, []);

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is signed in, see docs for a list of available properties
  //       // https://firebase.google.com/docs/reference/js/firebase.User
  //       const uid = user.uid;
  //       // ...
  //       console.log("uid", uid);
  //       setAuthenticated(true);
  //     } else {
  //       // User is signed out
  //       // ...
  //       console.log("user is logged out");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    console.log(games);
  }, [games]);

  useEffect(() => {
    console.log(game);
  }, [game]);

  return (
    <Sidebar
      children={
        <Box h="100%" px={3}>
          <HStack
            justify="space-between"
            // spacing={2}
            display={{ base: "none", md: "flex" }}
            borderBottom={useColorModeValue(
              "2px solid black",
              "2px solid white"
            )}
            py={2}
            overflow="auto"
          >
            {games.map((game) => (
              <Link key={game.id} to={game.id}>
                <Button
                  py={8}
                  px={4}
                  // isLoading
                  bg={
                    params.gameID != undefined
                      ? params.gameID == game.id
                        ? "red.500"
                        : "inherit"
                      : "inherit"
                  }
                  color={
                    params.gameID != undefined
                      ? params.gameID == game.id
                        ? "white"
                        : "inherit"
                      : "inherit"
                  }
                  _hover={{ background: "red.500", color: "white" }}
                  leftIcon={
                    game.opp_short ? (
                      <Image
                        // borderRadius="full"
                        boxSize={6}
                        src={`${imageURL}${game.opp_short.toLowerCase()}.gif`}
                      />
                    ) : null
                  }
                >
                  <VStack>
                    <Text>{game.opponent}</Text>
                    <Text
                      fontSize="xs"
                      fontWeight="normal"
                      fontFamily="monospace"
                    >
                      {moment(game.date.seconds * 1000).format("M/D/YY")}
                    </Text>
                  </VStack>
                </Button>
              </Link>
            ))}
          </HStack>
          <HStack
            justify="space-between"
            spacing={4}
            display={{ base: "flex", md: "none" }}
            borderBottom="2px solid black"
            py={2}
          >
            <Menu w="100%">
              <MenuButton
                w="100%"
                // bg="red.400"
                // color="white"
                // _hover={{ background: "red.800", color: "white" }}
                // colorScheme="black"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {game ? (
                  <Flex>
                    {game.opp_short != undefined ? (
                      <Image
                        // borderRadius="full"
                        boxSize={6}
                        // m={2}
                        src={`${imageURL}${game.opp_short.toLowerCase()}.gif`}
                      />
                    ) : (
                      ""
                    )}
                    <Text p={1} pl={2}>
                      {game.opponent}
                    </Text>
                  </Flex>
                ) : (
                  "Select Game"
                )}
              </MenuButton>
              <MenuList w="100%">
                {games.map((game) => (
                  <MenuItem
                    w="100%"
                    onClick={(e) => {
                      navigate(e.target.value);
                    }}
                    key={game.id}
                    value={game.id}
                  >
                    {game.opp_short ? (
                      <Image
                        // borderRadius="full"
                        boxSize={6}
                        m={2}
                        src={`${imageURL}${game.opp_short.toLowerCase()}.gif`}
                      />
                    ) : null}
                    {game.opponent}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </HStack>
          <Outlet context={[games, setGames]} />

          {/* <CurrentAvailability
            latest={ticketData[ticketData.length - 1]}
            games={games}
            game={game}
          />
          <TotalChart ticketData={ticketData} />

          <Sections ticketData={ticketData} /> */}
        </Box>
      }
    />
  );
};

export const FootballDetail = () => {
  const { gameID } = useParams();
  const location = useLocation();
  const [games, setGames] = useOutletContext();
  const [game, setGame] = useState(null);

  const [ticketData, setTicketData] = useState([]);

  const getData = async (game) => {
    let since = new Date(2022, 6, 1);

    const q = query(
      collection(db, "ticketData"),
      where("dateTime", ">=", since),
      where("game", "==", game)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const td = [];
      querySnapshot.forEach((doc) => {
        td.push(doc.data());
      });

      setTicketData(td);
    });
  };

  useEffect(() => {
    let thisGame = games.find((g) => g.id == gameID);
    setGame(thisGame);
    getData(gameID);
  }, [gameID, games]);

  useEffect(() => {
    console.log(games);
  }, [games]);

  useEffect(() => {
    console.log(ticketData);
  }, [ticketData]);

  return (
    <Box h="87%">
      {ticketData ? (
        <Box h="100%" pt={3} pb={5} overflow="scroll">
          <SimpleGrid pb={3} columns={{ base: 1, lg: 2 }} spacing={3}>
            <CurrentAvailability
              latest={ticketData[ticketData.length - 1]}
              games={games}
              game={gameID}
            />
            <TotalChart ticketData={ticketData} />
          </SimpleGrid>
          <Sections ticketData={ticketData} />
        </Box>
      ) : null}
    </Box>
  );
};
