import React, { useState, useEffect } from "react";

import { Box, Text, Heading, useColorModeValue } from "@chakra-ui/react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import { Football, FootballDetail } from "./components/Tix/Football";
import { Basketball, BasketballDetail } from "./components/Tix/Basketball";
import TixType from "./components/Tix/Terptix";
import AboutTerpTix from "./components/Tix/About";
import Logos from "./components/Logos";

import { Nav } from "./components/Nav";
import { Sidebar } from "./components/Sidebar";
import { Footer } from "./components/Footer";

const MarylandWillWin = () => {
  return (
    <BrowserRouter>
      <Box h="100vh">
        <Nav />
        <Box
          h={{ base: "calc(100vh - 148px)", md: "calc(100vh - 104px)" }}
          bg={useColorModeValue("white", "black")}
        >
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/ticket-tracker" element={<TixType />} />
            <Route path="/ticket-tracker/about" element={<AboutTerpTix />} />

            <Route path="/ticket-tracker/football/:year" element={<Football />}>
              <Route path=":gameID" element={<FootballDetail />} />
            </Route>

            <Route path="/uniform-db" element={<TixType />} />
            <Route path="/uniform-db/about" element={<AboutTerpTix />} />

            <Route path="/uniform-db/football" element={<Football />}>
              <Route path=":gameID" element={<FootballDetail />} />
            </Route>

            <Route
              path="/ticket-tracker/basketball/:year"
              element={<Basketball />}
            >
              <Route path=":gameID" element={<BasketballDetail />} />
            </Route>
          </Routes>
        </Box>
        <Footer />
      </Box>
    </BrowserRouter>
  );
};

export default MarylandWillWin;
