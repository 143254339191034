import * as React from "react";

import { Link } from "react-router-dom";

import {
  Container,
  Tag,
  Box,
  Text,
  Stack,
  HStack,
  Heading,
  Button,
  Alert,
  AlertIcon,
  CardBody,
  Card,
  CardFooter,
  Grid,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  CardHeader,
  useColorModeValue,
  VStack,
  Highlight,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import { Sidebar } from "../Sidebar";

import "../../firebase/config";

function TixType() {
  return (
    <Sidebar
      children={
        <Box p={3}>
          <Heading
            size="lg"
            // textAlign="center"
            color={useColorModeValue("red.500", "white")}
            // className="md-outline"
            fontFamily="Overpass"
            letterSpacing="3px"
            textDecoration="underline"
            p={3}
          >
            Tracking TerpTix
          </Heading>
          <Stack p={2}>
            <Link
              to="/ticket-tracker/football/2023"
              style={{ textDecoration: "none" }}
            >
              <Card>
                <CardHeader>
                  <Text>Football '23</Text>
                </CardHeader>
              </Card>
            </Link>

            <Link
              to="/ticket-tracker/basketball/2024"
              style={{ textDecoration: "none" }}
            >
              <Card>
                <CardHeader>
                  <Text>
                    Basketball '24{" "}
                    <Text fontSize="xs" as="em" color="red.700">
                      (New!)
                    </Text>
                  </Text>
                </CardHeader>
              </Card>
            </Link>
            <Alert borderRadius={5} mb={3} status="info">
              <VStack w="100%">
                <Text textAlign="center" fontSize="sm">
                  The data used in this tool is derived from the University of
                  Maryland ticket office -{" "}
                  <a href="https://umterps.com" target="_blank">
                    <Highlight
                      styles={{
                        p: 1,
                        px: 2,
                        bg: "red.400",
                        rounded: "full",
                      }}
                      query="umterps.com"
                    >
                      umterps.com
                    </Highlight>
                  </a>
                </Text>
                <Link to="/ticket-tracker/about">
                  <Button size="sm" colorScheme="blue" leftIcon={<InfoIcon />}>
                    <Text overflowWrap="break-word" mt={1}>
                      More About This Tool
                    </Text>
                  </Button>
                </Link>
              </VStack>
            </Alert>
          </Stack>
        </Box>
      }
    />
  );
}

export default TixType;
