import React, { useState, useEffect } from "react";

import moment from "moment";

import {
  Box,
  Flex,
  Text,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Select,
  FormControl,
  Menu,
  MenuItem,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  return (
    <Card bg={useColorModeValue("gray.100", "gray.700")}>
      {active && payload && payload.length ? (
        <CardBody
          p={2}
          fontSize="xs"
        >{`${label} : ${payload[0].value.toLocaleString(
          "en-US"
        )} available`}</CardBody>
      ) : null}
    </Card>
  );
};

export const TotalChart = ({ ticketData }) => {
  const [totalSeries, setTotalSeries] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState(false);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [range, setRange] = useState("dataMin");

  const changeRange = (e) => {
    setRange(e.target.value);
    setFilteredSeries(false);
    if (e.target.value != "dataMin") {
      let today = moment();
      let startDate = today
        .subtract(e.target.value, "days")
        .format("YYYY-MM-DD LT");
      let filtered = totalSeries.filter((s) => s.dateTime >= startDate);
      setFilteredSeries(filtered);
    }
  };

  const parseTotalSeries = (data) => {
    let totalData = data.map((entry) => {
      let tc = 0;
      let cn = 0;
      for (const section in entry.data) {
        var sect = entry.data[section];
        var subSections = sect["pl"];
        subSections.forEach((sub) => {
          tc += sub["tc"];
          cn += sub["cn"];
        });
      }
      return {
        dateTime: moment(entry.dateTime.seconds * 1000).format("YYYY-MM-DD LT"),
        total: tc,
        available: cn,
      };
    });
    setTotalSeries(totalData);
  };

  useEffect(() => {
    parseTotalSeries(ticketData);
    changeRange({ target: { value: "dataMin" } });
  }, [ticketData]);

  const XFormatter = (tickItem) => {
    if (tickItem) {
      return tickItem.split(" ")[0];
    }
  };

  return (
    <Card minH={"20em"} bg={useColorModeValue("white", "gray.900")}>
      <CardHeader>
        <Flex>
          <Text size="sm" fontFamily="monospace">
            Time Range:
          </Text>
          <Select
            value={range}
            label="Total Availability"
            onChange={changeRange}
          >
            <option value={"dataMin"}>All</option>
            <option value={30}>Month</option>
            <option value={14}>2 Weeks</option>
            <option value={7}>1 Week</option>
            <option value={1}>Day</option>
          </Select>
        </Flex>
      </CardHeader>

      <CardBody h="100%">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={filteredSeries ? filteredSeries : totalSeries}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="dateTime"
              tickFormatter={XFormatter}
              domain={[left, right]}
              allowDataOverflow
            />
            <YAxis domain={["auto", "auto"]} />
            <Tooltip content={CustomTooltip} />
            <Area
              type="monotone"
              dataKey="available"
              stroke="#000000"
              fill="#E03A3E"
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

export const SectionChart = ({ ticketData, section }) => {
  const [totalSeries, setTotalSeries] = useState([]);
  const [filteredSeries, setFilteredSeries] = useState(false);
  const [left, setLeft] = useState("dataMin");
  const [right, setRight] = useState("dataMax");
  const [range, setRange] = useState("dataMin");

  const changeRange = (e) => {
    setRange(e.target.value);
    setFilteredSeries(false);
    if (e.target.value != "dataMin") {
      let today = moment();
      let startDate = today
        .subtract(e.target.value, "days")
        .format("YYYY-MM-DD LT");
      let filtered = totalSeries.filter((s) => s.dateTime >= startDate);
      setFilteredSeries(filtered);
    }
  };

  const parseTotalSeries = (data, sectionName) => {
    let totalData = data.map((entry) => {
      let tc = 0;
      let cn = 0;
      for (const section in entry.data) {
        if (section == sectionName) {
          var sect = entry.data[section];
          var subSections = sect["pl"];
          subSections.forEach((sub) => {
            tc += sub["tc"];
            cn += sub["cn"];
          });
        }
      }
      return {
        dateTime: moment(entry.dateTime.seconds * 1000).format("YYYY-MM-DD LT"),
        total: tc,
        available: cn,
      };
    });
    setTotalSeries(totalData);
  };

  useEffect(() => {
    if (ticketData) {
      if (section) {
        parseTotalSeries(ticketData, section.section_name);
      }
    }
  }, [ticketData, section]);

  const XFormatter = (tickItem) => {
    if (tickItem) {
      return tickItem.split(" ")[0];
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={totalSeries}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateTime" tickFormatter={XFormatter} />
        <YAxis domain={[0, section ? section.total : 750]} />
        <Tooltip content={CustomTooltip} />
        <Area
          type="monotone"
          dataKey="available"
          stroke="#000000"
          fill="#E03A3E"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
