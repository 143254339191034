import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  chakra,
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";

import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FaGlobe, FaTwitter, FaBeer, FaCoffee } from "react-icons/fa";
import { ReactNode } from "react";

import { Link } from "react-router-dom";

export const Footer = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box
      bg={useColorModeValue("gray.100", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      position="absolute"
      bottom={0}
      width="100%"
    >
      <Container
        as={Stack}
        maxW={"8xl"}
        py={2}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        {/* <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={7}>
            
          </Stack>
        </Flex> */}
        <Stack direction="row">
          <Button size="xs" onClick={toggleColorMode}>
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Button>
          <Button
            as="a"
            href="https://jfkeniston.com"
            target="_blank"
            size="xs"
            rightIcon={<FaGlobe />}

            // variant="outline"
            //   colorScheme="red"
          >
            <Text mt={1}>Made by John Keniston</Text>
          </Button>
        </Stack>

        <Stack direction={"row"} spacing={6}>
          <Button
            rightIcon={<FaCoffee />}
            colorScheme="yellow"
            variant="solid"
            href={"https://bmc.link/jfkeniston"}
            target="_blank"
            size="xs"
            as="a"
          >
            <Text mt={1}>Buy me a coffee</Text>
          </Button>

          <Button
            label={"Twitter"}
            href={"https://twitter.com/jkgeospatial"}
            target="_blank"
            variant="solid"
            colorScheme="twitter"
            rightIcon={<FaTwitter />}
            as="a"
            size="xs"
          >
            <Text mt={1}>@jkgeospatial</Text>
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};
