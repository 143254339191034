import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import reportWebVitals from "./reportWebVitals";
import "@fontsource/alfa-slab-one";
import "@fontsource/overpass";

import MarylandWillWin from "./App";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import chakraTheme from "@chakra-ui/theme";

import { signInAnonymously } from "firebase/auth";
import { auth } from "./firebase/config";

signInAnonymously(auth)
  .then((x) => {
    // Signed in..
    console.log(x);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

const theme = extendTheme({
  fonts: {
    body: "Overpass, sans-serif",
    heading: "Overpass, sans-serif",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <MarylandWillWin />
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
